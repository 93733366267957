.terms-condition {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	&__modal {
		z-index: 1000 !important;
		padding: unset;
		width: 100%;
		max-width: unset;
		.esign-Modal__wrapper {
			border-radius: unset;
			padding: unset;
			background-color: rgba(255, 255, 255, 0.1);
			backdrop-filter: blur(1px);
			-webkit-backdrop-filter: blur(5px);
			max-width: 100%;
			width: 100%;
			margin: unset;
		}
	}

	&__header-wrapper {
		background-color: var(--color-bg-100-light);
		box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	}
	&__header-content {
		padding: 12px 16px;
		display: flex;
		justify-content: space-between;
	}
	&__recipient {
		display: flex;
		flex-direction: column;
		gap: 16px;
		width: 85%;
	}
	&__main-heading {
		font-size: var(--font-size-6);
		line-height: var(--line-height-6);
		text-overflow: ellipsis;
		overflow: hidden;
	}
	&__recipients-details-wrapper {
		display: flex;
		gap: 8px;
	}
	&__recipients-logo {
		width: 48px;
		height: 48px;
		background-color: lightgray;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: var(--font-size-7);
		color: gray;
		border-radius: 2px;
	}
	&__recipient-name {
		font-size: var(--font-size-3);
		line-height: var(--line-height-3);
		font-weight: 600;
		color: var(--color-text-90-light);
	}
	&__recipient-email {
		font-size: var(--font-size-2);
		line-height: var(--line-height-3);
		color: var(--color-text-70-light);
	}
	&__private-message {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0 16px 12px 16px;

	}
	&__message-heading {
		font-size: var(--font-size-3);
		font-weight: 600;
		line-height: var(--line-height-3);
		background-color: rgba(139, 69, 245, 1);
		width: fit-content;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		padding: 4px 16px;
		border-radius: 4px 4px 0px 0px;
	}
	&__message {
		font-size: var(--font-size-4);
		line-height: var(--line-height-4);
		border: 2px solid rgba(139, 69, 245, 1);
		border-top-width: 4px;
		padding: 12px 16px;
		border-radius: 0px 0px 4px 4px;
		background: linear-gradient(0deg, rgba(139, 69, 245, 0.06), rgba(139, 69, 245, 0.06));
		white-space: pre-wrap;
		word-break: break-word;

	}
	&__main-logo {
		width: 160px;
		height: 42px;
		object-fit: contain;
		margin-right: 20px;
	}

	&__header {
		border-bottom: 1px solid var(--color-border-90-light);
		background: var(--color-bg-100-light);
		display: flex;
	}
	&__banner {
		width: 100%;
		padding: calc(var(--padding) * 0.25) var(--padding);
		background: var(--color-esign-primary-light);
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: calc(var(--margin) * 0.25);
		min-height: 72px;
	}
	&__checkbox {
		width: 20px;
		height: 20px;
	}
	&__banner-heading {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: var(--font-size-2);
		color: var(--color-table-head-bg);
	}
	&__banner-sub-heading {
		display: flex;
        align-items: center;
        gap: 8px;
        justify-content: flex-start;
		div {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: calc(var(--margin) * 0.25);
			padding-bottom: 4px;
		}
		span {
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 600;
			font-size: var(--font-size-4);
			line-height: var(--line-height-4);
			color: var(--color-bg-100-light);
		}
	}
	&__body {
		border-bottom: 1px solid var(--color-border-90-light);
		height: calc(100vh - 160px);
		background-color: gray;
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(1px);
		opacity: 0.7;
	}
	&__footer {
		width: 100%;
		height: 80px;
		padding: var(--padding);
		display: flex;
		justify-content: flex-end;
		align-items: center;
		background-color: var(--color-bg-100-light);
		box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	}
	&__button {
		margin-top: unset !important;
		height: 48px;
		width: 120px;
	}
}

@media screen and (max-width: 480px) {
	.terms-condition {
		&__header-content {
			flex-direction: column-reverse;
		}
		&__banner-heading {
			text-align: left;
		}
		&__banner-sub-heading {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 8px;
			div {
				align-items: center;
                display: flex;
                justify-content: flex-start;
                margin-top: calc(var(--margin)*.25);
                text-wrap: wrap;
                padding-bottom: 4px;
			}
			.checkbox-storybook{
				&__label{
					&--text{
						white-space: inherit;
					}
				}

			}
		}
		&__body {
			height: calc(100vh - 170px);
		}
		&__footer {
			width: 100%;
            position: fixed;
            bottom: 0;
			.terms-condition__button {
				width: 100%;
				font-size: var(--font-size-4);
			}
		}
	}
}

@media screen and (min-width: 481px) {
	.terms-condition {
		position: relative;
		&__footer {
			position: absolute;
			bottom: 0;
		}
	}

}
